import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from "react";
import SimcardRepository from '../../Repositories/SimcardRepository';
import { FormControl, InputGroup } from 'react-bootstrap';
import Select from 'react-select'

const SimcardsSelect = ({ changeHandler, defaultValue, nodeId, disabled = false, updateError, eui = "" }) => {
  const [simcards, setSimcards] = useState<{ iccid, url }[]>([]);
  let gateway_isnull = "";

  // if new gateway - we need to get all usable sims
  // else use custom_simcards filter to get all usable sims + the one we already have
  if (defaultValue==undefined) {
    gateway_isnull = "true";
  }
  const urlParams = {
    params:
    {
      'offset': 0,
      'limit': 10000,
      'custom_simcards': eui,
      'gateway_isnull': gateway_isnull,
      'node__id' : nodeId
    }
  }
  useEffect(() => {

    async function getSimcards() {
      console.log("getSimcards async")
      SimcardRepository.Create().getSimcardsList(urlParams)
        .then(
          (response) => {
            setSimcards(response.data.results);
            console.log(simcards)

          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getSimcards Error:" + JSON.stringify(_event))
            setSimcards([{ "iccid": "N/A", url: null }]);
          }
        )
        .catch(
          (error) => {
            console.log('There has been a problem with your getSimcards operation: ' + error.message);
          }
        )
        ;
    }

    getSimcards();
  }, []);

  const options = simcards.map(sim => ({
    label: sim.iccid,
    value: sim.url,

  }));
  console.log("defaultvalue:" + defaultValue)
  console.log("options:" + JSON.stringify(options))

  const selectOption = simcards.length > 0
    ? options.find((e) => e.value === defaultValue)
    : null;

  console.log("defaultValue:" + JSON.stringify(selectOption))
  //  setSelectedOption(options[1])
  const handleSimcardInputChange = (event) => {
    console.log(event);
    if (event == null) {
      event = {
        target: {
          id: "simcard",
          value: ""
        },
        value: ""
      }

    } else {
      event.target = {
        id: "simcard",
        value: event.value
      }
    }
    changeHandler(event)
  };
  console.log("simcard default-value:" + defaultValue)
  return (
    <>
      {options.length > 0 ? (
        <InputGroup className="input-group-flex mb-3" hasValidation>
          <InputGroup.Text>Simcard</InputGroup.Text>

          <Select
            className={`form-control ${updateError["simcard"] ? "is-invalid" : ""}`}
            name="simcard"
            id="simcard"
            isClearable={true}
            isDisabled={(options.length == 0) }
            defaultValue={selectOption}
            options={options}
            onChange={handleSimcardInputChange}
          />
          <Form.Control.Feedback type="invalid">
            {updateError["simcard"]}
          </Form.Control.Feedback>
        </InputGroup>
      ) : (
        <InputGroup className="input-group-flex mb-3" hasValidation>
        <InputGroup.Text>Simcard</InputGroup.Text>
        <div className={'form-control'}>Simcard Not Accessible</div>
        </InputGroup>

      )}

    </>
  );
};

export default SimcardsSelect;
