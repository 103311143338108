import React from "react";
import TabsMenu, { Tab } from "../Common/TabsMenu";

interface TabsProps {
    eui: string | undefined;  // or the appropriate type based on usage
}

const Tabs= ({eui}) => {
    const basePath = "/sensors/" + eui;
    const tabs: Tab[] = [
        { to: "", name: "View" },
        { to: "edit", name: "Edit" },
        //{ to: "decode", name: "Decode", disabled: true },
        { to: "downlink", name: "Downlink", disabled: true },
        { to: "log", name: "Log", disabled: true },
    ];

    return (
        <TabsMenu basePath={basePath} tabs={tabs} />
    );
}

export default Tabs;
