import React, { useContext, useState } from "react";
import Form from 'react-bootstrap/Form';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import NodeSelect from "../Common/NodeSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import MqttAccountRepository from "../../Repositories/MqttAccountRepository";
import { AppContext } from "../../App";

const MqttAccountCreate = () => {
  const { userProfile } = useContext(AppContext);
  const [mqttAccount, setMqttAccount] = useState({
    username: undefined,
    password_hash: undefined,
    node: undefined,
  });
  const [updateError, setUpdateError] = useState([]);
  
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    switch (id) {
      default:
        setMqttAccount((prev) => ({
          ...prev,
          [id]: value,
        }));
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    console.log(mqttAccount);
    MqttAccountRepository.Create().createMqttAccount(mqttAccount)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );


  };

  const handleEditorChange = (value, event) => {
    setMqttAccount((prev) => ({
      ...prev,
      ["decoder"]: value,
    }));
  }

  // setup
  const navigate = useNavigate();

  return (
    <div>
      <Header>Create MQTT Account</Header>

      <Divider width="100%" />
      <PageBody>
        <Form onSubmit={handleSubmit} noValidate >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">

            <div className="col-sm-8">
              <p>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Username'
                    id='username'
                    value={mqttAccount.username}
                    isInvalid={updateError["username"]}

                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["username"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </p>
              <p>
                <NodeSelect changeHandler={handleInputChange} defaultValue={mqttAccount.node} updateError={updateError}></NodeSelect>
              </p>
              <p hidden={!userProfile.is_staff}>
                <Form.Group>
                  <Form.Label>Password Hash</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Password Hash'
                    isInvalid={updateError["password_hash"]}
                    id='password_hash'
                    value={mqttAccount.password_hash}
                    onChange={handleInputChange}
                    required
                    disabled={!userProfile.is_staff}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["password_hash"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </p>

              <div className="row">
                <div className="col mb-2" >
                  <div className="d-flex justify-content-between">
                    <div className="col start-0">
                      <Button variant="primary" type="submit" value="Create" disabled={!userProfile.is_staff}>Create</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>


      </PageBody>
    </div>
  );
}

export default MqttAccountCreate;
