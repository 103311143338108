import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import { BorderAllRounded } from '@mui/icons-material';

const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'zoom-out',
}

const boxStyle = {
    bgcolor: 'background.paper',
    border: '2px solid black',
    boxShadow: 24,
    p: 4,
};

const imgStyle = {
    maxWidth: '90vw',
    maxHeight: '90vh',
}

const ImageWithModal = ({file}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
        <img onClick={handleOpen} src={file} style={{ width: '300px', cursor: 'zoom-in' }} />
        <Modal
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={modalStyle}
            >
            <Box sx={boxStyle}>
                <img src={file} style={imgStyle}/>
            </Box>
        </Modal>
    </>
  );
}

export default ImageWithModal;
