import React, { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Header from "../Header";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import NodeSelect from "../Common/NodeSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import DeleteButton from "../Common/DeleteButton";
import MqttAccountRepository from "../../Repositories/MqttAccountRepository";
import { AppContext } from "../../App";

type MqttAccount = {
  id, username, node, password_hash
}

const MqttAccountEdit = () => {
  const { userProfile } = useContext(AppContext);
  const [mqttAccount, setMqttAccount] = useState<MqttAccount>();
  var { mqttAccountId } = useParams();
  const [updateError, setUpdateError] = useState("");

  useEffect(() => {
    async function getMqttAccount(mqttAccountId) {
      try {
        const response = await MqttAccountRepository.Create().getMqttAccount(mqttAccountId);
        setMqttAccount(response.data);
        console.log(response.data)
      } catch (error) {
        console.log("getMqttAccount Error: " + JSON.stringify(error));
        setMqttAccount({ "id": null, "username": null, "node": null, password_hash: null });
      }
    }
    console.log("mqttAccountId=" + mqttAccountId)
    getMqttAccount(mqttAccountId);
  }, [mqttAccountId]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log(id, value)
    switch (id) {
      default:
        setMqttAccount((prevMqttAccount) => ({
          ...prevMqttAccount,
          [id]: value,
        } as MqttAccount));
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    MqttAccountRepository.Create().updateMqttAccount(mqttAccount)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  };

  const handleEditorChange = (value, event) => {
    setMqttAccount((prevMqttAccount) => ({
      ...prevMqttAccount,
      ["decoder"]: value,
    } as MqttAccount));
  }

  const handleDelete = (event) => {
    MqttAccountRepository.Create().deleteMqttAccount(mqttAccount?.id)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  }

  // setup
  const navigate = useNavigate();

  return (
    <div>
      <Header>MQTT Account: {mqttAccount?.username || 'Undefined'}</Header>
      <PageBody>
        <Form onSubmit={handleSubmit} noValidate >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">

            <div className="col-sm-8">
              <p>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Username'
                    id='username'
                    value={mqttAccount?.username || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["username"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["name"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </p>
              <p>
                <NodeSelect changeHandler={handleInputChange} defaultValue={mqttAccount?.node} updateError={updateError} ></NodeSelect>
              </p>
              <p hidden={!userProfile.is_staff}>
                <Form.Label>Password Hash</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder='Password Hash'
                  id='password_hash'
                  value={mqttAccount?.password_hash || ''}
                  onChange={handleInputChange}
                  isInvalid={updateError["password_hash"]}
                  disabled={!userProfile.is_staff}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {updateError["model"]}
                </Form.Control.Feedback>
              </p>
              <p>
                {updateError["detail"] && (
                  <div className="error">
                    {updateError["detail"]}
                  </div>
                )}
              </p>

              <div className="row">
                <div className="col mb-2" >
                  <div className="d-flex justify-content-between">
                    <div className="col start-0">
                      <Button variant="primary" type="submit" value="Update">Update</Button>
                    </div>
                    <div className="col end-0">
                      <DeleteButton entityName={mqttAccount?.username} onDelete={handleDelete} disabled={!userProfile.is_staff} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </PageBody>
    </div>
  );
}

export default MqttAccountEdit;
