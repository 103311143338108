import React from 'react';
import Select from 'react-select'

const SenseReactSelect = ({ changeHandler, defaultValue, isDisabled, isInvalid, isClearable = true, id, name, options = null }) => {
    const selectOption = options.length > 0
        ? options.find((e) => e.value === defaultValue)
        : null;

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: 'none',        // Removes the border
            boxShadow: 'none',     // Removes the box shadow (optional)
            padding: 0,            // Removes padding around the control
            '&:hover': {
                border: 'none',      // Ensures no border appears on hover
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: 0,
            radius: 0           // Removes padding inside the container where the value is displayed
        }),
        input: (provided) => ({
            ...provided,
            margin: 0,             // Removes margin around the input text
            padding: 0,
            radius: 0           // Removes padding inside the input
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            padding: 0,
            radius: 0            // Removes padding around the dropdown indicator and clear indicator
        }),
    };

  const handleTypeInputChange = (event) => {
    console.log(event);
    if (event == null) {
      event = {
        target: {
          id: id,
          value: ""
        },
        value: ""
      }

    } else {
      // Update event structure with standardized target object
      event.target = {
        id: id,
        value: event.value
      }
    }
    changeHandler(event)
  };

  return (
        <Select
            className={`form-control ${isInvalid ? "is-invalid" : ""}`}
            isClearable={isClearable}
            name={name}
            id={id}
            aria-label="Default select example"
            onChange={handleTypeInputChange}
            value={selectOption}
            styles={customStyles}
            isDisabled={isDisabled}
            options={options}
        />
  );
}

export default SenseReactSelect;
