import { AxiosRequestConfig } from "axios";
import api from "../Service/api";

class MqttAccountRepository
{
    static Create(): MqttAccountRepository {
        return new MqttAccountRepository();
    }

    getMqttAccounts(config: AxiosRequestConfig) {
        return api.get("mqtt-accounts/", config);
    }

    getMqttAccount(id) {
      return api.get("mqtt-accounts/" + id + "/");
    }

    createMqttAccount(mqttAccount) {
        return api.post('mqtt-accounts/', mqttAccount);
    }

    updateMqttAccount(mqttAccount) {
      return api.put(mqttAccount.url, mqttAccount);
    }
    
    deleteMqttAccount(id) {
      return api.delete("mqtt-accounts/" + id);
    }
}

export default MqttAccountRepository;
