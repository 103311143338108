import { AxiosRequestConfig } from 'axios';
import api from '../Service/api';

class NodeRepository {
    static Create(): NodeRepository {
        return new NodeRepository();
    }

    getTenants(config: AxiosRequestConfig) {
      return api.get("nodes/", config);
    }

    getNodes(searchParams) {
      return api.get("nodes/", searchParams);
    }

    getNodesParents(config: AxiosRequestConfig) {
      return api.get("nodesparents/", config);
    }
    
    getNode(id) {
        return api.get("nodes/" + id)
    }

    createTenant(tenant) {
      return api.post("/nodes/", tenant);
    }

    updateTenant(tenant) {
      return api.put(tenant.url, tenant);
    }

    deleteTenant(id) {
      return api.delete("nodes/" + id)
    }
}

export default NodeRepository;
