import React, { useState } from "react";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { DebounceInput } from 'react-debounce-input';
import { useParams, useLocation } from 'react-router-dom';
import Header from "../Header";
import { useNavigate } from 'react-router-dom';
import { Button, Accordion, Form, Row, Col, ToastContainer, Card, InputGroup } from 'react-bootstrap';
import placeholderImg from './placeholder-image.jpg';
import SensorTypesSelect from "./SensorTypesSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import LocationMap from "../Common/LocationMap";
import NetworksSelect from "./NetworkSelect";
import NodeSelect from "../Common/NodeSelect";
import NetworkDisabled from "./NetworkDisabled";
import SendDownlink from "./SendDownlink";
import ShowNotification from "../Common/ShowNotification"
import DeleteButton from "../Common/DeleteButton";
import SensorRepository from "../../Repositories/SensorRepository";
import Tabs from "./Tabs";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";
import AsyncPageBody from "../Common/AsyncPageBody";
import whereAmI from "../functions/whereAmI";

const { REACT_APP_PROVISION_RELATIVE_URL } = process.env;
const REACT_APP_PROVISION_URL = window.location.origin + REACT_APP_PROVISION_RELATIVE_URL;

type Sensor = {
  eui, join_eui, application_key, type, active, name, description, city, location, network, node, third_party_data
}

const defaultSensor = {
  eui: null,
  join_eui: null,
  application_key: null,
  type: null,
  active: false,
  name: null,
  description: null,
  city: null,
  location: null,
  network: null,
  node: null,
  third_party_data: null
}

const Sensor = () => {
  const [sensor, setSensor] = useState<Sensor>(defaultSensor);
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState("");
  var { sensorId } = useParams();
  const { search } = useLocation();
  const urlQuery = new URLSearchParams(search);
  const [updateError, setUpdateError] = useState("");
  const [showDownlink, setShowDownlink] = useState(false);
  const handleCloseDownlink = () => setShowDownlink(false);
  const handleShowDownlink = () => setShowDownlink(true);
  const [errorNotification, setErrorNotification] = useState(false)
  const [notification, setNotification] = useState({ show: false, message: "", type: "" });
  const asyncEffectState = useAsyncEffect({
    fetchAsync: (abortSignal) => {
      async function getSensor(sensorId) {
        const response = await SensorRepository.Create().getSensor(sensorId, abortSignal);
        setSensor(response.data);
        setLocation(getLocationArray(response.data.location));
        if (response.data.img != null) {
          setFile(response.data.img);
        } else {
          setFile(placeholderImg);
        }
      }

      return getSensor(sensorId);
    }, deps: [sensorId]
  });

  const provider = new OpenStreetMapProvider();
  const handleNotification = (message, type) => {
    setNotification({ show: true, message, type });
  };

  function doSearchAddress(value) {
    console.log("searching for value:" + JSON.stringify(value))
    provider.search({ query: value }).then(function (result) {
      console.log("openstreetmap result" + JSON.stringify(result))
      if (result.length > 0) {
        const { x, y, label } = result[0];
        setLocation({ lat: y, lng: x, zoom: 14 });
        setSensor(prevSensor => ({ ...prevSensor, location: y + "," + x }));
      }
    });
  }

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log(id, value)
    switch (id) {
      case 'location':
        console.log("setting location to: " + JSON.stringify(value))
        setLocation(getLocationArray(value));
        break;
      case 'city':
        doSearchAddress(value);
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: value,
        }));
        break;
      case 'active':
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: !(value === 'Active'),
        }));
        break;

      default:
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: value,
        }));
    }
  };

  const handleDelete = (event) => {
    SensorRepository.Create().deleteSensor(sensorId)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
          handleNotification(updateError, error)
          //          setErrorNotification(true);
        }
      );
  }

  const handleImageChange = (e) => {
    setSensor(prevSensor => ({ ...prevSensor, img: e.target.files[0] }));
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = event => {
    event.preventDefault();

    SensorRepository.Create().updateSensor(sensor)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.log("updatesensor failed")
          console.error(error);

          handleApiError(error, setUpdateError)
          console.log(updateError)
          if (updateError['detail']) {
            handleNotification(updateError['detail'], error)

            //            setErrorNotification(true)
          }
        }
      );
  };

  const getLocationArray = (locationstr) => {
    const [lat, lng] = String(locationstr).split(',');
    if (Number.isNaN(Number.parseFloat(lat)) || Number.isNaN(Number.parseFloat(lng))) {
      return null;
    } else {
      return { lat: +lat, lng: +lng, zoom: 14 };
    }
  };

  const updateLocation = () => {
    console.log("Attempting to get location");
    whereAmI()
      .then(({latitude,longitude}) => 
      {
        
        doSearchAddress(latitude + ' , ' + longitude)

      })
      .catch((error) => console.error('Error:', error));
  }

  const navigate = useNavigate();

  return (
    <>
      <Header>Sensor {sensorId || ''}</Header>
      <Tabs eui={sensorId} />
      <AsyncPageBody state={asyncEffectState}>
        <form noValidate onSubmit={handleSubmit} >
          <Row md="auto">

            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>General</Card.Header>
                <Card.Body>
                  <InputGroup className="mb-3 input-group-flex" hasValidation >
                    <InputGroup.Text>Name</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder='Name of the sensor.. '
                      id='name'
                      value={sensor.name || ''}
                      onChange={handleInputChange}
                      required
                      isInvalid={updateError['name']}
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["name"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-3 input-group-flex" hasValidation >
                    <InputGroup.Text>Description</InputGroup.Text>
                    <Form.Control as="textarea" aria-label="Description"

                      rows={3}
                      placeholder='Give your sensor a nice description.. '
                      id='description'
                      value={sensor.description || ''}
                      onChange={handleInputChange}
                      required
                      isInvalid={updateError['description']}

                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["description"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex" hasValidation>
                    <InputGroup.Text >Address</InputGroup.Text>

                    <DebounceInput className="form-control"
                      label='address'
                      minLength={2}
                      debounceTimeout={500}
                      type="text"
                      placeholder='Address'
                      id='city'
                      value={sensor.city || ''}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["city"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex" hasValidation>
                    <InputGroup.Text >Coordinates</InputGroup.Text>
                    <div className="form-control" style={{ display: 'flex', gap: '8px' }}>
                      <DebounceInput
     className="form-control-sm form-control" 
                        label='location'
                        type="text"
                        placeholder='fx. 55.6928608,12.5992622'
                        id='location'
                        value={sensor.location}
                        onChange={handleInputChange}
                      />
                      <Button onClick={updateLocation} >My Position</Button>
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {updateError["location"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="input-group-flex" hasValidation>
                    <InputGroup.Text >Set status to</InputGroup.Text>
                    <Button
                      className="form-control"
                      id="active"
                      variant={(sensor.active ? "success" : "danger")}
                      onClick={handleInputChange}
                      value={sensor.active ? "Active" : "Not Active"}
                    >{sensor.active ? "Active" : "Not Active"}</Button>
                    <Form.Control.Feedback type="invalid">
                      {updateError["active"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                </Card.Body>
              </Card>
            </Col>
            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>Map</Card.Header>
                <Card.Body>
                  <LocationMap location={location} setLocation={setLocation} setDevice={setSensor} />

                </Card.Body>
              </Card>
            </Col>

            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>Image</Card.Header>
                <Card.Body >
                  <div className="mb3" style={{ height: '15em', overflow: 'hidden', marginBottom: '1em' }}>
                    <img src={file} style={{ width: '27em', textAlign: 'center' }} className="mb-3" />
                  </div>
                  <InputGroup>
                    <Form.Control
                      className="form-control col-3"
                      type="file"
                      id="img"
                      accept="image/png, image/jpeg"
                      onChange={handleImageChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["img"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                </Card.Body>
              </Card>
            </Col>

            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>Advanced</Card.Header>
                <Card.Body>
                  <InputGroup className="mb-3 input-group-flex" hasValidation >
                    <InputGroup.Text>EUI</InputGroup.Text>
                    <Form.Control type="eui" placeholder="XXXXXXXXXXXXXXXX"
                      id="eui" required value={sensor.eui || ''} name="eui" readOnly={true}
                      disabled />
                    <Form.Control.Feedback type="invalid">
                      {updateError["eui"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-3 input-group-flex" hasValidation>
                    <InputGroup.Text>Join EUI</InputGroup.Text>
                    <Form.Control type="join_eui"
                      onChange={handleInputChange} name="join_eui"
                      maxLength={16}
                      placeholder="XXXXXXXXXXXXXXXX" id="join_eui"
                      readOnly={true}
                      disabled
                      value={sensor.join_eui || ''} />
                    <Form.Control.Feedback type="invalid">
                      {updateError["join_eui"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-3 input-group-flex" hasValidation>
                    <InputGroup.Text>App Key</InputGroup.Text>
                    <Form.Control type="application_key"
                      onChange={handleInputChange} name="application_key"
                      maxLength={32}
                      isInvalid={updateError["application_key"]}
                      placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" id="application_key" required value={sensor.application_key || ''} />
                    <Form.Control.Feedback type="invalid">
                      {updateError["application_key"]}
                    </Form.Control.Feedback>
                  </InputGroup >

                  <SensorTypesSelect changeHandler={handleInputChange} defaultValue={sensor.type} updateError={updateError}></SensorTypesSelect>
                  <NodeSelect changeHandler={handleInputChange} defaultValue={sensor.node} updateError={updateError}></NodeSelect>
                  {sensor.active
                    ? <NetworkDisabled sensor={sensor} />
                    : <NetworksSelect changeHandler={handleInputChange} defaultValue={sensor.network} updateError={updateError}></NetworksSelect>
                  }


                  <InputGroup className="mb-3 input-group-flex">
                    <InputGroup.Text>Loriot Data</InputGroup.Text>
                    <Form.Control as="textarea" style={{ fontSize: "small" }}
                      rows={3}
                      name="third_party_data"
                      value={JSON.stringify(JSON.parse(sensor.third_party_data || null), null, 2)}
                      disabled />
                  </InputGroup >

                </Card.Body>
              </Card>
            </Col>
          </Row>

          <ToastContainer
            className="p-3"
            position="middle-center"
            style={{ zIndex: 1 }}>
            <ShowNotification
              show={notification.show}
              setShow={setNotification}
              message={notification.message}
              timeout={3000}
            />
          </ToastContainer>

          <Row>
            <Col className=" mb-2" >
              <div className="d-flex justify-content-between">
                <Col className="start-0">
                  <Button variant="primary" type="submit" value="Update">Update</Button>
                </Col>
                <Col className="midddle-0">
                  <Button variant="primary" onClick={handleShowDownlink}>
                    Send Downlink
                  </Button>

                  <SendDownlink eui={sensor.eui} showDownlink={showDownlink} handleClose={handleCloseDownlink} />
                </Col>
                <Col className="end-0">
                  <DeleteButton entityName={sensor.name} onDelete={handleDelete} />
                </Col>
              </div>
            </Col>
          </Row>


        </form>
      </AsyncPageBody>
    </>
  );
}

export default Sensor;
