import React, { useEffect, useState } from "react";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { DebounceInput } from 'react-debounce-input';
import { useParams, useLocation } from 'react-router-dom';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import placeholderImg from './placeholder-image.jpg';
import { handleApiError } from "../functions/apiErrorHandling";
import LocationMap from "../Common/LocationMap";
import {Form, Button, Row, Col, ToastContainer, InputGroup, Card} from 'react-bootstrap';
import ShowNotification from "../Common/ShowNotification";
import GatewayTypesSelect from "./GatewayTypesSelect";
import LorawanNetworkSelect from "./LorawanNetworkSelect";
import NodeSelect from "../Common/NodeSelect";
import DeleteButton from "../Common/DeleteButton";
import GatewayRepository from "../../Repositories/GatewayRepository";
import AsyncPageBody from "../Common/AsyncPageBody";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";
import SimcardsSelect from "./SimcardsSelect";
const { REACT_APP_PROVISION_RELATIVE_URL } = process.env;
const REACT_APP_PROVISION_URL = window.location.origin + REACT_APP_PROVISION_RELATIVE_URL;

type Gateway = {
  eui, name, description, mac, type, lorawan_network, node, active, city, location,username,password,simcard,node__id
}

const defaultGateway: Gateway = {
  eui: null,
  name: null,
  description: null,
  mac: null,
  type: null,
  lorawan_network: null,
  node: null,
  active: false,
  city: null,
  location: null,
  username:null, 
  password:null,
  simcard:null,
  node__id:null
}

const Gateway = () => {
  const [gateway, setGateway] = useState<Gateway>(defaultGateway);
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState("");
  var { gatewayId } = useParams();
  const { search } = useLocation();
  const urlQuery = new URLSearchParams(search);
  const [updateError, setUpdateError] = useState("");
  const [errorNotification, setErrorNotification] = useState(false)
  const [passwordType, setPasswordType] = useState("password");

  const asyncEffectState = useAsyncEffect({
    fetchAsync: (abortSignal) => {
      async function getGateway(gatewayId) {
        {
          const response = await GatewayRepository.Create().getGateway(gatewayId, abortSignal);
          setGateway(response.data);
          console.log("Gateway is: " + gateway.eui)
          console.log(gateway)
          setLocation(getLocationArray(response.data.location));
          if (response.data.img != null) {
            setFile(response.data.img);
          } else {
            setFile(placeholderImg);
          }
        }
      }
      console.log("gatewayID" + JSON.stringify(gatewayId))
      if ((gatewayId == undefined) || (gatewayId.length != 16)) {
        console.log("Search" + search)
        gatewayId = urlQuery.get("eui");
        console.log("gatewayID" + JSON.stringify(gatewayId))
      }

      return getGateway(gatewayId);
    }, deps: [gatewayId]
  });

  const provider = new OpenStreetMapProvider();

  function doSearchAddress(value) {
    console.log("searching for value:" + JSON.stringify(value))
    provider.search({ query: value }).then(function (result) {
      console.log("openstreetmap result" + JSON.stringify(result))
      if (result.length > 0) {
        const { x, y, label } = result[0];
        setLocation({ lat: y, lng: x, zoom: 14 });
        setGateway(prevGateway => ({ ...prevGateway, location: y + "," + x }));
      }
    });
  }

  const togglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  }

  const handleInputChange = (event) => {
    console.log(event);
    // For single select

    const { id, value } = event.target;
    console.log("id,value:", id, value)
    switch (id) {
      case 'location':
        console.log("setting location to: " + JSON.stringify(value))
        setLocation(getLocationArray(value));
        break;
      case 'city':
        doSearchAddress(value);
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: value,
        }));
        break;
      case 'active':
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: !(value === 'Active'),
        }));
        break;

      default:
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: value,
        }));
    }
  };

  const handleDelete = (event) => {
    GatewayRepository.Create().deleteGateway(gatewayId)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
          setErrorNotification(true)
        }
      );
  }

  const handleImageChange = (e) => {
    setGateway(prevGateway => ({ ...prevGateway, img: e.target.files[0] }));
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = event => {
    event.preventDefault();

    GatewayRepository.Create().updateGateway(gateway)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.log("Recordservice.updategateway failed")
          console.error(error);
          handleApiError(error, setUpdateError)
          setErrorNotification(true)
        }
      );
  };

  const getLocationArray = (locationstr) => {
    const [lat, lng] = String(locationstr).split(',');
    if (Number.isNaN(Number.parseFloat(lat)) || Number.isNaN(Number.parseFloat(lng))) {
      return null;
    } else {
      return { lat: +lat, lng: +lng, zoom: 14 };
    }
  };

  const navigate = useNavigate();

  return (
    <div>
      <Header>Editing EUI: {gateway.eui || ''}</Header>
      <Divider width="100%" />
      <AsyncPageBody state={asyncEffectState}>
        <form noValidate onSubmit={handleSubmit} >
          <Row md="auto">
            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>Essentials</Card.Header>
                <Card.Body>
                  <InputGroup className="mb-3 input-group-flex" >
                    <InputGroup.Text>Name</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder='Name of the gateway.. '
                      id='name'
                      value={gateway.name || ''}
                      onChange={handleInputChange}
                      isInvalid={updateError['name']}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["name"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex" >
                    <InputGroup.Text>Description</InputGroup.Text>
                    <Form.Control as="textarea" aria-label="Description"

                      rows={3}
                      placeholder='Give your gateway a nice description.. '
                      id='description'
                      value={gateway.description || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex">
                    <InputGroup.Text >Address</InputGroup.Text>

                    <DebounceInput className="form-control"
                      label='address'
                      minLength={2}
                      debounceTimeout={500}
                      type="text"
                      placeholder='Address'
                      id='city'
                      value={gateway.city || ''}
                      onChange={handleInputChange}
                    />

                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex">
                    <InputGroup.Text >Coordinates</InputGroup.Text>

                    <DebounceInput
                      className="form-control form-control-sm"
                      label='location'
                      type="text"
                      placeholder='fx. 55.6928608,12.5992622'
                      id='location'
                      value={gateway.location}
                      onChange={handleInputChange}
                      required
                    />

                  </InputGroup>

                  <InputGroup className="input-group-flex">
                    <InputGroup.Text >Set status to</InputGroup.Text>
                    <Button
                      className="form-control"
                      id="active"
                      variant={(gateway.active ? "success" : "danger")}
                      onClick={handleInputChange}
                      value={gateway.active ? "Active" : "Not Active"}
                    >{gateway.active ? "Active" : "Not Active"}</Button>

                  </InputGroup>
                </Card.Body>
              </Card>
            </Col>

            <Col className="mb-3">
            <Card className="gateway-card">
              <Card.Header>Map</Card.Header>
              <Card.Body>
              <LocationMap location={location} setLocation={setLocation} setDevice={setGateway} />

              </Card.Body>
            </Card>
            </Col>

            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>Advanced Settings</Card.Header>
                <Card.Body>
                  <InputGroup className="mb-3 input-group-flex" >
                    <InputGroup.Text>MAC</InputGroup.Text>
                    <Form.Control type="mac" placeholder="aa:bb:cc:aa:bb:cc"
                      onChange={handleInputChange}
                      id="mac" required value={gateway.mac} name="mac"
                      maxLength={17}
                      isInvalid={updateError["mac"]}

                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["mac"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-3 input-group-flex">
                    <InputGroup.Text>EUI</InputGroup.Text>
                    <Form.Control type="eui" placeholder="XXXXXXXXXXXXXXXX"
                      onChange={handleInputChange}
                      id="eui"
                      required
                      value={gateway.eui}
                      name="eui"
                      maxLength={16}
                      isInvalid={updateError["eui"]}

                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["eui"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex" hasValidation>
                    <InputGroup.Text>Username</InputGroup.Text>
                    <Form.Control type="username" placeholder="username"
                      onChange={handleInputChange}
                      id="username"
                      autoComplete="name"
                      required
                      value={gateway.username}
                      name="username"                  
                      maxLength={100}
                      isInvalid={updateError["username"]}

                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["username"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex" hasValidation>
                    <InputGroup.Text>Password</InputGroup.Text>
                    <Form.Control type={passwordType} placeholder="password"
                      onChange={handleInputChange}
                      id="password"
                      required
                      autoComplete="new-password"
                      value={gateway.password}
                      name="password"
                      maxLength={100}
                      isInvalid={updateError["password"]}

                    />
                    <Button onClick={togglePasswordType}>
                      {passwordType === "password" ? (
                        <svg
                          width="20"
                          height="17"
                          fill="currentColor"
                          className="bi bi-eye-slash-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                          <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="17"
                          fill="currentColor"
                          className="bi bi-eye-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                        </svg>
                      )}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      {updateError["password"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <GatewayTypesSelect changeHandler={handleInputChange} defaultValue={gateway.type}></GatewayTypesSelect>
                  <NodeSelect changeHandler={handleInputChange} defaultValue={gateway.node}></NodeSelect>
                  <LorawanNetworkSelect changeHandler={handleInputChange} defaultValue={gateway.lorawan_network}></LorawanNetworkSelect>
                  <SimcardsSelect changeHandler={handleInputChange} defaultValue={gateway.simcard} nodeId={gateway.node__id} eui={gateway.eui} updateError={updateError}/>

                  </Card.Body>
              </Card>
            </Col>
            <Col className="mb-3">
            <Card className="gateway-card">
              <Card.Header>Image</Card.Header>
              <Card.Body>
              <p>
                  <img src={file} style={{ width: '200px' }} />
                </p>
              
                  <input
                    className="form-control col-3"
                    type="file"
                    id="img"
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                  />
              </Card.Body>
            </Card>
            </Col>


          </Row>

          <ToastContainer
            className="p-3"
            position="middle-center"
            style={{ zIndex: 1 }}>
            <ShowNotification
              show={errorNotification}
              setShow={setErrorNotification}
              timeout={3000}
              message={""}>
              <div className="general-error">{updateError["detail"]} </div>

            </ShowNotification>
          </ToastContainer>


          <Row  className="justify-content-md-center">

                <Col xs lg="2">
                  <Button variant="primary" type="submit" value="Update">Update</Button>
                </Col>

                <Col xs lg="2">
                  <DeleteButton entityName={gateway.name} onDelete={handleDelete} />
                </Col>

          </Row>

        </form>
      </AsyncPageBody>
    </div>
  );
}

export default Gateway;
