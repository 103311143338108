import React from 'react';
import Toast from 'react-bootstrap/Toast';

const ShowNotification = ({message="",timeout,show,setShow, children=<></>}) => {

  return (

        <Toast onClose={() => setShow(false)} show={show} delay={timeout} autohide>
          <Toast.Header>

            <strong className="me-auto">Notification</strong>
            <small>Just Now</small>
          </Toast.Header>
          <Toast.Body>{message}{children}</Toast.Body>
        </Toast>

  );
}

export default ShowNotification;
