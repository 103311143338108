import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";
import NodeRepository from "../../Repositories/NodeRepository";
import AxiosRequestConfigBuilder from "../../Extensions/Axios/AxiosRequestConfigBuilder";
import SensorTypeRepository from "../../Repositories/SensorTypeRepository";
import NetworkRepository from "../../Repositories/NetworkRepository";
import SensorRepository from "../../Repositories/SensorRepository";
import { AxiosError } from "axios";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { toast } from 'react-toastify';

const SensorImportButton = () => {
    const euiRegex = "^([a-fA-F0-9]{16})|(([a-fA-F0-9]{2}-){7}[a-fA-F0-9]{2})$";
    const [isCsvImportOpen, setIsCsvImportOpen] = useState(false);
    const onClose = () => { setIsCsvImportOpen(false); };
    const onSubmit = async (data, file) => {
        const sensors = data.validData;
        const promises = [];
        let failed = [];
        let succeeded = [];
        for (const sensor of sensors)
        {
            sensor["node"] = "https://sampl.local/nodes/"+sensor["node"] +"/";
            sensor["network"] = "https://sampl.local/networks/"+sensor["network"] +"/";
            sensor["type"] = "https://sampl.local/sensortypes/"+sensor["type"] +"/";
        }
        
        const chunkSize = 100;
        for (let i = 0; i < sensors.length; i += chunkSize) {
            const chunk = sensors.slice(i, i + chunkSize);
            await
                SensorRepository.Create()
                .batchCreate(chunk)
                .then((response) => {
                    console.log(response);
                    for (const s of chunk) {
                        succeeded.push(s);
                    }
                })
                .catch((reason: AxiosError) => {
                    console.log(reason);
                    if (reason.status != 400) {
                        throw reason;
                    }

                    const data = reason.response.data as any[];
                    for (const i in data) {
                        const entry = reason.response.data[i];
                        const sensor = chunk[i];
                        let error_message = "";                    
                        for (const [k, v] of Object.entries(entry)) {
                            error_message += k + ": " + v + ". ";
                        }

                        if (error_message == "") {
                            sensor["x_import_status"] = "OK";
                            succeeded.push(sensor);
                        } else {
                            sensor["x_import_status"] = "FAILED";
                            sensor["x_import_message"] = error_message;
                            failed.push(sensor);
                        }
                    }
                });
            const handled = Math.min(sensors.length, i + chunkSize);
            toast("Handled "+ handled +" of "+ sensors.length +" sensors.", { type: "info", autoClose: 3000, position: "bottom-left" });
        }

        return Promise.all(promises).then((value) => {
            console.log({
                succeeded: succeeded,
                failed: failed,
            });
            const rows = [];
            for (const r of failed) {
                rows.push(r);
            }
            for (const r of succeeded) {
                rows.push(r);
            }

            const csvConfig = mkConfig({
                fieldSeparator: ';',
                decimalSeparator: ',',
                useKeysAsHeaders: true,
                quoteStrings: true,
                filename: (""+file.path).replace(RegExp("\.(csv|xls|xlsx)$", "i"),"") + "_import_result",
            });

            if (failed.length > 0) {
                if (succeeded.length > 0) {
                    toast(
                        "Import partially completed! ("+ succeeded.length +" sensors imported.) View downloaded CSV for details.",
                        { type: "warning", autoClose: false, position: "bottom-left" });
                } else {
                    toast("Import failed! View downloaded CSV for details.", { type: "error", autoClose: false, position: "bottom-left" });
                }
                exportRows(csvConfig, rows);
            } else {
                toast("Import completed! ("+ rows.length +" sensors imported.)", { type: "success", autoClose: 10000, position: "bottom-left" });
            }
        });
    }
    
    const exportRows = (csvConfig, rows: any[]) => {
        const csv = generateCsv(csvConfig)(rows);
        download(csvConfig)(csv);
    };

    const [sensorTypes, setSensorTypes] = useState([{
        id: "",
        name: ""
    }]);
    const [networks, setNetworks] = useState([{
        id: "",
        name: ""
    }]);
    const [nodes, setNodes] = useState([{
        id: "",
        path: ""
    }]);

    const configBuilder = AxiosRequestConfigBuilder.create().withPaginationLimitAndOffset(1000);

    useAsyncEffect({ fetchAsync: (abortSignal) => {
        async function getSensorTypes() {
          SensorTypeRepository.Create().getSensorTypes(configBuilder.withAbortSignal(abortSignal).build())
            .then(
              (response) => {
                setSensorTypes(response.data.results.sort((a, b) => a.name.localeCompare(b.name)));
              },
              (error) => {
                const _event =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
                console.log("getSensorTypes Error:" + JSON.stringify(_event));
                setSensorTypes([{ "id": "N/A", "name": "N/A" }]);
              }
            )
            .catch(
              (error) => {
                console.log('There has been a problem with your getSensorTypes operation: ' + error.message);
              }
            )
            ;
        }
    
        return getSensorTypes();
    }, deps: []});

    useAsyncEffect({ fetchAsync: (abortSignal) => {
        async function getNetworks() {
          NetworkRepository.Create().getNetworks(configBuilder.withAbortSignal(abortSignal).build())
            .then(
              (response) => {
                setNetworks(response.data.results.sort((a, b) => a.name.localeCompare(b.name)));
              },
              (error) => {
                const _event =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
                console.log("getNetworks Error:" + JSON.stringify(_event));
                setNetworks([{ "id": "N/A", "name": "N/A" }]);
              }
            )
            .catch(
              (error) => {
                console.log('There has been a problem with your getNetworks operation: ' + error.message);
              }
            )
            ;
        }
    
        return getNetworks();
    }, deps: []});

    useAsyncEffect({ fetchAsync: (abortSignal) => {
        async function getNodes() {
          NodeRepository.Create().getNodes(configBuilder.withAbortSignal(abortSignal).build())
            .then(
              (response) => {
                setNodes(response.data.results.sort((a, b) => a.path.localeCompare(b.path)));
              },
              (error) => {
                const _event =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
                console.log("getNodes Error:" + JSON.stringify(_event));
                setNodes([{ "id": "N/A", "path": "N/A" }]);
              }
            )
            .catch(
              (error) => {
                console.log('There has been a problem with your getNodes operation: ' + error.message);
              }
            )
            ;
        }
    
        return getNodes();
    }, deps: []});

    const csvImportFields = [
        {
            label: "EUI",
            key: "eui",
            fieldType: {
                type: "input"
            },
            validations: [{
                rule: "required",
                errorMessage: "EUI is required",
                level: "error",
            },{
                rule: "unique",
                errorMessage: "EUI must be unique",
                level: "error",
            },{
                rule: "regex",
                value: euiRegex,
                errorMessage: "EUI must be 16 digit hex",
                level: "error",
            }],
        },
        {
            label: "Join EUI",
            key: "join_eui",
            alternateMatches: ["join eui", "join key"],
            fieldType: {
                type: "input"
            },
            validations: [{
                rule: "required",
                errorMessage: "Join EUI is required",
                level: "error",
            },{
                rule: "regex",
                value: euiRegex,
                errorMessage: "Join EUI must be 16 digit hex"
            }],
        },
        {
            label: "Application Key",
            key: "application_key",
            alternateMatches: ["application key", "app key", "appkey", "app_key"],
            fieldType: {
                type: "input"
            },
            validations: [{
                rule: "required",
                errorMessage: "Application Key is required",
            },{
                rule: "regex",
                value: "^[a-f0-9]{32}$",
                flags: "i",
                errorMessage: "Application Key must be 32 digit hex",
            }],
        },
        {
          // Visible in table header and when matching columns.
          label: "Name",
          // This is the key used for this field when we call onSubmit.
          key: "name",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["first name", "first"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          //example: "Stephanie",
          // Can have multiple validations that are visible in Validation Step table.
          validations: [
            {
              // Can be "required" / "unique" / "regex"
              rule: "required",
              errorMessage: "Name is required",
              // There can be "info" / "warning" / "error" levels. Optional. Default "error".
              level: "error",
            },
          ],
        },
        {
            label: "Description",
            key: "description",
            fieldType: {
                type: "input"
            },
            validations: [{
                rule: "required",
                errorMessage: "Description is required",
                level: "error",
            }],
        },
        {
            label: "Type",
            key: "type",
            fieldType: {
                type: "select",
                options: sensorTypes.map(x => ({label: x.name, value: ""+x.id})),
            },
            validations: [{
                rule: "required",
                errorMessage: "Type is required",
            }],
        },
        {
            label: "Network",
            key: "network",
            fieldType: {
                type: "select",
                options: networks.map(x => ({label: x.name, value: ""+x.id})),
            },
            validations: [{
                rule: "required",
                errorMessage: "Network is required",
            }],
        },
        {
            label: "Node",
            key: "node",
            fieldType: {
                type: "select",
                options: nodes.map(n => ({label: "["+n.id+"] " + n.path, value: ""+n.id})),
            },
            validations: [{
                rule: "required",
                errorMessage: "Node is required",
            }],
        },
        {
            label: "Active",
            key: "active",
            fieldType: {
                type: "checkbox"
            },
        },
    ] as const;
    
    var existingEUIs = new Set<string>();
    var notFoundEUIs = new Set<string>();

    if (! isCsvImportOpen)
        return <Button variant="primary" onClick={() => setIsCsvImportOpen(true)}>Import</Button>;

    return (
        <>
            <ReactSpreadsheetImport
                isOpen={isCsvImportOpen}
                onClose={onClose}
                onSubmit={onSubmit}
                //maxRecords={1000}
                fields={csvImportFields}
                allowInvalidSubmit={false}
                isNavigationEnabled={true}
                autoMapSelectValues={true}
                selectHeaderStepHook ={async (headerValues, data) => {
                    const map = {
                        "node": RegExp("^https:\/\/.+?\/nodes\/([0-9]+)\/$", 'i'),
                        "type": RegExp("^https:\/\/.+?\/sensortypes\/([0-9]+)\/$", 'i'),
                        "network": RegExp("^https:\/\/.+?\/networks\/([0-9]+)\/$", 'i'),
                    }
                    data = extractEntityIdFromUrl(headerValues, data, map);
                    return {
                        headerValues,
                        data
                    };

                    function extractEntityIdFromUrl(headerValues, data, map) {
                        for (const i in headerValues) {
                            const header = headerValues[i];
                            if (! (header in map))
                                continue;

                            const regex = map[header];
                            for (const j in data) {
                                let v = data[j][i];

                                const match = regex.exec(v);
                                if (match && match.length == 2) {
                                    v = match[1];
                                }
                                
                                data[j][i] = v;
                            }
                        }

                        return data;
                    }
                }}
                matchColumnsStepHook={async (table, rawData, columns) => {
                    const euis = table.map(e => e.eui);
                    const result = await SensorRepository.Create().batchCheckExist({euis: euis});
                    existingEUIs = new Set<string>(result.data.found);
                    notFoundEUIs = new Set<string>(result.data.not_found);
                    console.log(result.data);
                    return table;
                }}
                rowHook={async(row, addError) => {
                    const rowEUI = row["eui"];
                    if (typeof rowEUI === "string") {
                        if (existingEUIs.has(rowEUI)) {
                            addError("eui", {message: "Sensor already exists with this EUI.", level: "error"});
                        } else if (! notFoundEUIs.has(rowEUI)) {
                            console.log("Checking single eui: " + rowEUI);
                            await SensorRepository.Create().getByEui(rowEUI)
                            .then((sensor) => {
                                if (sensor != null) {
                                    existingEUIs.add(rowEUI);
                                    addError("eui", {message: "Sensor already exists with this EUI.", level: "error"});
                                } else {
                                    notFoundEUIs.add(rowEUI);
                                }
                            });
                        }
                    }
                    return row;
                }}
            />
            <Button variant="primary" onClick={() => setIsCsvImportOpen(true)}>Import</Button>
        </>
    );
}

export default SensorImportButton;
