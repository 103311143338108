import React, { useState } from "react";
import { DebounceInput } from 'react-debounce-input';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, InputGroup, Form, Row, Col, ToastContainer, Card } from 'react-bootstrap';
import QRCodeRepository from "../../Repositories/QRCodeRepository";
import SensorTypesSelect from "./SensorTypesSelect";
import NetworksSelect from "./NetworkSelect";
import NodeSelect from "../Common/NodeSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import LocationMap from "../Common/LocationMap";
import { toast } from 'react-toastify';
import SensorRepository from "../../Repositories/SensorRepository";
import ShowNotification from "../Common/ShowNotification"
import whereAmI from "../functions/whereAmI";
type Sensor = {
  eui, join_eui, application_key, type, active, name, description, city, location, node, network
}

const defaultSensor = {
  eui: null,
  join_eui: null,
  application_key: null,
  type: null,
  active: false,
  name: null,
  description: null,
  city: null,
  location: null,
  node: null,
  network: null
}

const Sensor = () => {
  const [searchParams] = useSearchParams();
  const [sensor, setSensor] = useState<Sensor>({ ...defaultSensor, ["eui"]: searchParams?.get("eui") });
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState("");
  const [updateError, setUpdateError] = useState([]);
  const [errorNotification, setErrorNotification] = useState(false)
  const [notification, setNotification] = useState({ show: false, message: "", type: "" });

  const provider = new OpenStreetMapProvider();

  function doSearchAddress(value) {
    provider
      .search({ query: value })
      .then(function (result) {
        if (result.length > 0) {
          const { x, y, label } = result[0];
          setLocation({ lat: y, lng: x, zoom: 14 });
          setSensor(prevSensor => ({ ...prevSensor, location: y + "," + x }));
        }
      });
  }
  const handleNotification = (message, type) => {
    setNotification({ show: true, message, type });
  };

  const handleInputChange = (event) => {
    console.log("Create.js (119):")
    const { id, value } = event.target;
    console.log(id, value)
    switch (id) {
      case 'location':
        setLocation(getLocationArray(value));
        break;
      case 'city':
        doSearchAddress(value);
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: value,
        }));
        break;
      case 'active':
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: !(value === 'Active'),
        }));
        break;
      default:
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: value,
        }));
    }
  };

  const handleImageChange = (e) => {
    setSensor(prevSensor => ({ ...prevSensor, img: e.target.files[0] }));
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = event => {
    event.preventDefault();

    SensorRepository.Create().createSensor(sensor)
      .then(async (response) => {
        console.log('Item created:', response.data);
        let qr = searchParams?.get("qr");
        if (qr != null) {
          await QRCodeRepository.Create().sensorCreated(qr, sensor.eui);
        }
        toast("Sensor created!", { type: "success", autoClose: 3000 });
        setTimeout(() => navigate("/sensors/" + sensor.eui), 3000);
      })
      .catch(
        error => {
          toast("Error creating sensor.", { type: "error" });
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  };

  const getLocationArray = (locationstr) => {
    const [lat, lng] = String(locationstr).split(',');
    if (Number.isNaN(Number.parseFloat(lat)) || Number.isNaN(Number.parseFloat(lng))) {
      return null;
    } else {
      return { lat: +lat, lng: +lng, zoom: 14 };
    }
  };

  const updateLocation = () => {
    console.log("Attempting to get location");
    whereAmI()
      .then(({ latitude, longitude }) => {

        doSearchAddress(latitude + ' , ' + longitude)

      })
      .catch((error) => console.error('Error:', error));
  }

  const navigate = useNavigate();


  
  return (
    <div>
      <Header>New sensor {sensor.eui || ''}</Header>

      <Divider width="100%" color="red" />
      <PageBody>
        <form noValidate onSubmit={handleSubmit} >
          <Row md="auto">

            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>General</Card.Header>
                <Card.Body>
                  <InputGroup className="mb-3 input-group-flex" >
                    <InputGroup.Text>Name</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder='Name of the sensor.. '
                      id='name'
                      value={sensor.name || ''}
                      onChange={handleInputChange}
                      required
                      isInvalid={updateError['name']}
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["name"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-3 input-group-flex" >
                    <InputGroup.Text>Description</InputGroup.Text>
                    <Form.Control as="textarea" aria-label="Description"

                      rows={3}
                      placeholder='Give your sensor a nice description.. '
                      id='description'
                      value={sensor.description || ''}
                      onChange={handleInputChange}
                      required
                      isInvalid={updateError['description']}
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["description"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex">
                    <InputGroup.Text >Address</InputGroup.Text>

                    <DebounceInput className="form-control"
                      label='address'
                      minLength={2}
                      debounceTimeout={500}
                      type="text"
                      placeholder='Address'
                      id='city'
                      value={sensor.city || ''}
                      onChange={handleInputChange}
                    />

                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex" hasValidation>
                    <InputGroup.Text >Coordinates</InputGroup.Text>
                    <div className="form-control" style={{ display: 'flex', gap: '8px' }}>
                      <DebounceInput
                        className="form-control-sm form-control"
                        label='location'
                        type="text"
                        placeholder='fx. 55.6928608,12.5992622'
                        id='location'
                        value={sensor.location}
                        onChange={handleInputChange}
                      />
                      <Button onClick={updateLocation} >My Position</Button>
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {updateError["location"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="input-group-flex">
                    <InputGroup.Text >Set status to</InputGroup.Text>
                    <Button
                      className="form-control"
                      id="active"
                      variant={(sensor.active ? "success" : "danger")}
                      onClick={handleInputChange}
                      value={sensor.active ? "Active" : "Not Active"}
                    >{sensor.active ? "Active" : "Not Active"}</Button>

                  </InputGroup>

                </Card.Body>
              </Card>
            </Col>
            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>Map</Card.Header>
                <Card.Body>
                  <LocationMap location={location} setLocation={setLocation} setDevice={setSensor} />

                </Card.Body>
              </Card>
            </Col>

            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>Image</Card.Header>
                <Card.Body >
                  <div className="mb3" style={{ height: '15em', overflow: 'hidden' }}>
                    <img src={file} style={{ width: '27em' }} className="mb-3" />
                  </div>
                  <input
                    className="form-control col-3"
                    type="file"
                    id="img"
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>Advanced</Card.Header>
                <Card.Body>
                  <InputGroup className="mb-3 input-group-flex" >
                    <InputGroup.Text>EUI</InputGroup.Text>
                    <Form.Control type="eui" placeholder="XXXXXXXXXXXXXXXX"
                      onChange={handleInputChange}
                      id="eui" required value={sensor.eui} name="eui"
                      maxLength={16}
                      isInvalid={updateError["eui"]}

                    />                    
                    <Form.Control.Feedback type="invalid">
                    {updateError["eui"]}
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-3 input-group-flex">
                    <InputGroup.Text>Join EUI</InputGroup.Text>
                    <Form.Control type="join_eui"
                      onChange={handleInputChange} name="join_eui"
                      maxLength={16}
                      placeholder="XXXXXXXXXXXXXXXX" id="join_eui"
                      value={sensor.join_eui } 
                      isInvalid={updateError["join_eui"]}
                      />
                    <Form.Control.Feedback type="invalid">
                      {updateError["join_eui"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-3 input-group-flex">
                    <InputGroup.Text>App Key</InputGroup.Text>
                    <Form.Control type="application_key"
                      onChange={handleInputChange} name="application_key"
                      maxLength={32}
                      isInvalid={updateError["application_key"]}
                      placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" id="application_key" required value={sensor.application_key || ''} />
                    <Form.Control.Feedback type="invalid">
                      {updateError["application_key"]}
                    </Form.Control.Feedback>
                  </InputGroup >

                  <SensorTypesSelect changeHandler={handleInputChange} defaultValue={sensor.type} updateError={updateError} />
                  <NodeSelect changeHandler={handleInputChange} defaultValue={sensor.node} updateError={updateError} />
                  <NetworksSelect changeHandler={handleInputChange} defaultValue={sensor.network} updateError={updateError} />


                </Card.Body>
              </Card>
            </Col>



          </Row>


          <p >
            <ToastContainer
              className="p-3"
              position="middle-center"
              style={{ zIndex: 1 }}>
              <ShowNotification
                show={errorNotification}
                setShow={setErrorNotification}
                timeout={3000}
                message={updateError["detail"]}>
                <div className="general-error">{updateError["detail"]} </div>

              </ShowNotification>
            </ToastContainer>
          </p>
          <p>
            <Row>
              <Col className=" mb-2" >
                <div className="d-flex justify-content-between">
                  <Col className="start-0">
                    <Button variant="primary" type="submit" value="Update">Update</Button>
                  </Col>
                </div>
              </Col>
            </Row>
          </p>

        </form>
      </PageBody>
    </div>
  );
}

export default Sensor;
