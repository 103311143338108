import Form from 'react-bootstrap/Form';
import React from "react";
import NodeSelectField from './NodeSelectField';
import { InputGroup } from 'react-bootstrap';

const NodeSelect = ({
  changeHandler,
  defaultValue,
  updateError={},
  disabled=false,
  elementId="node",
  valueKey="url",
  label="Owner"
}) => {
  
  return (
    <>
      <InputGroup className='mb-3 input-group-flex' hasValidation={true}>
        <InputGroup.Text hidden={label == null}>{label}</InputGroup.Text>

        <NodeSelectField
          changeHandler={changeHandler}
          defaultValue={defaultValue}
          updateError={updateError}
          disabled={disabled}
          elementId={elementId}
          valueKey={valueKey}
          
        />
        <Form.Control.Feedback type="invalid">
        {updateError[elementId]}
      </Form.Control.Feedback>
      </InputGroup>
    </>
  );
};


export default NodeSelect;
