import React from "react";
import { Routes, Route } from "react-router-dom";
import MqttAccountCreate from "./Create";
import MqttAccountEdit from "./Edit";
import MqttAccounts from "./MqttAccounts";

const MqttAccountRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MqttAccounts/>} />
            <Route path="/create/" element={<MqttAccountCreate/>} />
            <Route path="/:mqttAccountId/*">
                <Route path="edit" element={<MqttAccountEdit/>} />
            </Route>
        </Routes>
    );
}

export default MqttAccountRoutes;
