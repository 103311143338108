import React, { useMemo, useContext } from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import EntityListTableView from "../Common/EntityListTableView";
import { AppContext } from "../../App";
import MqttAccountRepository from "../../Repositories/MqttAccountRepository";
import { MRT_ColumnDef } from "material-react-table";

const MqttAccounts = () => {
    const { userProfile } = useContext(AppContext);
    
    const columns = useMemo(
        () => {
            let columns: MRT_ColumnDef<any, any>[] = [{
                accessorKey: 'id',
                header: 'Id',
                size: 10,
            },
            {
                accessorKey: 'username',
                header: 'Username',
                size: 100,
            }];

            columns.push({
                enableColumnActions: false,
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: 'id',
                header: 'Edit',
                size: 20,
                Cell: ({ row,cell }) => (
                    <Link to={`/mqtt-accounts/${cell.getValue()}/edit`}>
                        <Button size="sm" variant={'outline-success'}>edit</Button>
                    </Link>
                )
            });

            return columns;
        },
        [userProfile.is_staff],
    );
    
    return (
        <div>
            <Header>MQTT Accounts</Header>

            <Divider />
            <PageBody>
                <EntityListTableView
                    entityNamePlural="MqttAccounts"
                    entityFetch={(config) => MqttAccountRepository.Create().getMqttAccounts(config)}
                    columns={columns}
                    getRowId={(row) => row.id}
                />
                <p hidden={!userProfile.is_staff}>
                    <Link to="/mqtt-accounts/create/"><Button variant="primary">Create New</Button></Link>
                </p>
            </PageBody>

        </div>
    );
}

export default MqttAccounts;
