import React from "react";
import { Nav } from "react-bootstrap";
import { Link, To, useLocation } from "react-router-dom";

export type Tab = {
    to: To,
    name: string,
    disabled?: boolean
};

function TabsMenu({basePath, tabs} : {basePath: string, tabs: Tab[]}) {
    const location = useLocation();
    const stripTrailingSlash = (path: string) => path.endsWith("/") ? path.substring(0, path.length-1) : path;
    const linkPath = (to: To) => stripTrailingSlash(basePath + "/" + to);

    let path = stripTrailingSlash(location.pathname);

    return (
        <Nav variant="tabs" defaultActiveKey={path}>
            { tabs.map((t) =>
                <Nav.Item key={t.name}>
                    <Nav.Link
                        to={linkPath(t.to)}
                        eventKey={linkPath(t.to)}
                        as={Link}
                        disabled={t.disabled}>{t.name}
                    </Nav.Link>
                </Nav.Item>
            )}
        </Nav>
    );
}

export default TabsMenu;
