import React from 'react';
import ReactTimeAgo from 'react-time-ago';

const SenseTimeAgo = ({ time } : { time: Date | number}) => {
    if (time == null)
    {
        return "Never";
    }

    return <ReactTimeAgo date={time} locale="en-GB" />;
}

export default SenseTimeAgo;
