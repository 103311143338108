import React from "react";
import TabsMenu, { Tab } from "../Common/TabsMenu";

function Tabs({id}) {
    const basePath = "/sensortypes/" + id;
    const tabs: Tab[] = [
        { to: "", name: "View", disabled: true },
        { to: "edit", name: "Edit" },
        { to: "decoder-test", name: "Decoder Test", disabled: true },
    ];

    return (
        <TabsMenu basePath={basePath} tabs={tabs} />
    );
}

export default Tabs;
