import { AxiosRequestConfig } from 'axios';
import api from '../Service/api';
import ObjectFormDataMapper, { SkipProperty } from '../Libs/HTTP/ObjectFormDataMapper';

class SensorRepository {
  readonly skippedProperties: SkipProperty[] = [
    ObjectFormDataMapper.SkipProperty("third_party_data"),
    ObjectFormDataMapper.SkipImagePropertyWhenNotAFile("img"),
    ObjectFormDataMapper.SkipProperty("owner"),
  ];
  
    static Create(): SensorRepository {
        return new SensorRepository();
    }
    
    getSensor(eui, abortSignal?: AbortSignal) {
        return api.get("sensors/" + eui + "/", { signal: abortSignal });
    }

    getSensors(config: AxiosRequestConfig) {
        return api.get("sensors/", config);
    }

    getSensorsList(config: AxiosRequestConfig) {
        return api.get("sensors/", config);
    }
    
    async getByEui(eui, abortSignal?: AbortSignal) {
        let result = await api.get("sensors/" + eui + "/", { validateStatus: (status) => status == 200 || status == 404, signal: abortSignal });
        if (result.status == 404) {
            return null;
        }

        if (result.status != 200) {
            throw new Error("Could not fetch sensor, http ("+ result.status +"): " + result.statusText);
        }

        return result.data;
    }

    createSensor(sensor, abortSignal?: AbortSignal) {      
        return api.postForm("sensors/", sensor, { signal: abortSignal }, this.skippedProperties);
    }

    updateSensor(sensor, abortSignal?: AbortSignal) {
        return api.putForm(sensor.url, sensor, { signal: abortSignal }, this.skippedProperties);
    }

    deleteSensor(id, abortSignal?: AbortSignal) {
      return api.delete("sensors/" + id + "/", { signal: abortSignal })
    }

    batchCheckExist(euis, abortSignal?: AbortSignal) {
        return api.post("sensors/batch/check-existence/", euis, { signal: abortSignal });
    }

    batchCreate(sensors, abortSignal?: AbortSignal) {
        return api.post("sensors/batch/", sensors, { signal: abortSignal });
    }
}
 
export default SensorRepository;
