import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import RecordService from '../../Service/record.service';
import { InputGroup } from "react-bootstrap";

const NetworkDisabled = ({ sensor }) => {
  function GetObjectName(objectUrl) {
    const [objectName, setObjectName] = useState('');

    useEffect(() => {
      RecordService.getUrlName(objectUrl)
        .then((response) => {
          setObjectName(response.data.name);
        })
        .catch((error) => {
          console.error(error);
        });
    }, [objectUrl]);

    return objectName;
  };

  return (
    <InputGroup className="mb-3 input-group-flex">
      <InputGroup.Text>Application</InputGroup.Text>
      <Form.Control type="text"
        name="network"
        disabled
        value={GetObjectName(sensor.network)} />
    </InputGroup>
  )
}

export default NetworkDisabled;
