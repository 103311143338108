import { AxiosRequestConfig } from 'axios';
import api from '../Service/api';

class NetworkRepository {
    static Create(): NetworkRepository {
        return new NetworkRepository();
    }

    getNetworks(config: AxiosRequestConfig) {
      return api.get("networks/", config);
    }

    getApplications(searchParams) {
      return this.getNetworks(searchParams);
    }
    
    getNetwork(networkId) {
      return api.get("networks/" + networkId);
    }

    createNetwork(network) {
      return api.post('networks/', network);
    }

    updateNetwork(network) {
      return api.put(network.url, network);
    }

    deleteNetwork(id) {
      return api.delete("networks/" + id)
    }
}

export default NetworkRepository;
