interface Coordinates {
  latitude: number;
  longitude: number;
}

const whereAmI = (): Promise<Coordinates> => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject("Geolocation is not supported by your browser");
      }
  
      function success(position: GeolocationPosition) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(latitude,longitude)
        resolve({ latitude, longitude }); // Resolve with coordinates
      }
  
      function error() {
        reject("Unable to retrieve your location");
      }
  
      navigator.geolocation.getCurrentPosition(success, error);
    });
  };
  
  export default whereAmI;
