import { Form, InputGroup } from 'react-bootstrap';
import React, { useState, useMemo } from "react";
import NetworkRepository from '../../Repositories/NetworkRepository';
import AxiosRequestConfigBuilder from '../../Extensions/Axios/AxiosRequestConfigBuilder';
import useAsyncEffect from '../../Extensions/React/AsyncEffect/UseAsyncEffect';
import SenseReactSelect from '../Common/SenseReactSelect';
const NetworksSelect = ({ changeHandler, defaultValue, updateError }) => {
  const [networks, setNetworks] = useState<{ id, name, url }[]>([]);
  const configBuilder = AxiosRequestConfigBuilder.create()
    .withPaginationLimitAndOffset(1000);

  useAsyncEffect({
    fetchAsync: (abortSignal) => {
      async function getNetworks() {
        console.log("getNetworks async - defaultvalue:" + defaultValue)
        NetworkRepository.Create().getNetworks(configBuilder.withAbortSignal(abortSignal).build())
          .then(
            (response) => {
              console.log("Response data" + JSON.stringify(response.data))
              setNetworks(response.data.results);
              console.log(networks)

            },
            (error) => {
              const _event =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              console.log("getNetworks Error:" + JSON.stringify(_event))
              setNetworks([{ id: "N/A", name: "N/A", url: null }])
            }
          )
          .catch(
            (error) => {
              console.log('There has been a problem with your getsites operation: ' + error.message);
            }
          )
          ;
      }

      return getNetworks();
    }, deps: [networks.length]
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log(id, value)
    changeHandler(event)

  }


  // Generate options for the Select component
  const options = useMemo(() => networks.map(network => ({
    label: network.name,
    value: network.url,
  })), [networks]); // Recompute when sensorTypes changes

  const isInvalid = () => (updateError && updateError["network"] !== undefined) ? true : false;
  return (
    <>
      {
        // Check if sensorTypes are loaded
        options.length > 0 ? (
          <InputGroup className='input-group-flex mb-3' hasValidation>
            <InputGroup.Text>Application</InputGroup.Text>
            <SenseReactSelect
              changeHandler={changeHandler} // Handle change events
              defaultValue={defaultValue}           // Set default value
              isInvalid={isInvalid()}     // Manage error state
              isDisabled={false}
              id={"network"}
              name={"network"}
              options={options}
            // Pass available options
            />
            <Form.Control.Feedback type="invalid">
              {updateError ? updateError["network"] : ""}
            </Form.Control.Feedback>
          </InputGroup>
        ) : (
          <p>Loading...</p> // Or show a loading spinner
        )}
    </>
  );

};

export default NetworksSelect;
