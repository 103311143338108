import React, { useMemo } from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import { useMediaQuery } from 'react-responsive'
import {Row,Col} from 'react-bootstrap'
import SensorRepository from "../../Repositories/SensorRepository";
import EntityListTableView from "../Common/EntityListTableView";
import { MRT_ColumnDef } from "material-react-table";
import LastSeen from "../Common/LastSeen";
import Battery from "../Common/Battery";
import SensorImportButton from "./SensorImportButton";

type Sensor = {
    eui, join_eui, application_key, type, active, name, description, city, location, last_seen, battery_state, last_gateway_eui
}

const Sensors = ({ ...props }) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const columns = useMemo(
        (): MRT_ColumnDef<Sensor, any>[] => [
            {
                accessorKey: 'active',
                header: 'Active',
                size: 110,
                Cell: ({ cell }) => (
                    <div>{(cell.getValue() ? "Yes" : "No")}</div>
                ),
                filterFn: 'equals',
                filterSelectOptions: ['True', 'False'],
                filterVariant: 'select',
            },
            {
                accessorKey: 'eui',
                header: 'EUI',
                Cell: ({ cell }) => (
                    <Link to={`/sensors/${cell.getValue()}`}>{cell.getValue()}</Link>
                ),
            },
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorKey: 'city',
                header: 'Address',
            },
            {
                accessorKey: 'description',
                header: 'Description',
            },
            {
                id: 'type__name',
                accessorKey: 'type__name',
                header: 'Type',
                enableSorting: false // not available in backend atm
            },
            {
                id: 'network__name',
                accessorKey: 'network__name',
                header: 'Application',
                enableSorting: false // not available in backend atm
            },
            {
                id: 'node__name',
                accessorKey: 'node__name',
                header: 'Owner',
                enableSorting: false // not available in backend atm
            },
            {
                accessorKey: 'eui',
                id: 'lastSeen',
                header: 'Status',
                enableSorting: false,
                enableColumnFilter: false,
                Cell: ({ row }) =>
                    <>
                        <LastSeen value={row.original.last_seen} />&nbsp;<Battery value={row.original.battery_state} />
                    </>
            },
        ],
        [],
    );
    
    return (
        <div>
            <Header>Sensors</Header>

            <Divider />
            <PageBody>
                <EntityListTableView
                    entityNamePlural="Sensors"
                    entityFetch={(config) => SensorRepository.Create().getSensorsList(config)}
                    columns={columns}
                    columnVisibility={{
                        "name": isDesktopOrLaptop,
                        "city": isDesktopOrLaptop,
                        "description": isDesktopOrLaptop,
                        "network__name": false,
                        "node__name": false
                    }}
                    getRowId={(row) => row.eui}
                />
                <br />
                    <Row>
                         <Col className=" mb-2" >
                            <div className="d-flex justify-content-between">
                                <Col className="start-0">
                                    <Link to="/sensors/create/"><Button variant="primary">Create New</Button></Link>
                                    &nbsp;<SensorImportButton />
                                </Col>
                                <Col className="midddle-0">
                                    <Link to="/qr/codes/create">
                                        <Button variant="primary">
                                            Create QRcodes
                                        </Button>
                                    </Link>
                                </Col>
                            </div>
                        </Col>
                    </Row>
            </PageBody>

        </div>
    );
}

export default Sensors;
