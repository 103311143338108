import React, { createContext, useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.css"
import './Components/SamplSidebar'
import Dashboard from './Components/Dashboard/Dashboard';
import './App.css';
import SamplSideBar from './Components/SamplSidebar';
import { Routes, Route } from "react-router-dom";
import Billing from './Components/Billing';
import Support from './Components/Support';
import Databases from './Components/Databases/Databases';
import Flows from './Components/Flows';
import UserProfile from './Components/UserProfile';
import Login from './Components/Login/Login';
import useToken from './Service/useToken';
import Footer from './Components/Footer';
import ChangePassword from './Components/ChangePassword';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import AccountRoutes from './Components/Accounts/Routes';
import ApplicationRoutes from './Components/Applications/Routes';
import GatewayRoutes from './Components/Gateways/Routes';
import QRRoutes from './Components/QR/Routes';
import SensorRoutes from './Components/Sensors/Routes';
import SensorTypeRoutes from './Components/SensorTypes/Routes';
import TenantRoutes from './Components/Tenants/Routes';
import UserRoutes from './Components/Users/Routes';
import SimcardsRoutes from './Components/Simcards/Routes'
import UserRepository from './Repositories/UserRepository';
import { useMediaQuery } from 'react-responsive';
import MqttAccounts from './Components/MQTT/MqttAccounts';
import MqttAccountRoutes from './Components/MQTT/Routes';

type UserProfile = {
  email: string,
  first_name: string,
  last_name: string,
  username: string,
  is_staff: boolean
}

type Node = {
  id: number,
  name: string,
  path: string
}

export type AppContext = {
  parentCallback: () => void
  setToken: (userToken: any) => void
  userProfile: UserProfile
  isDesktopOrLaptop: boolean,
  globalViewNode: Node
  setGlobalViewNode: React.Dispatch<React.SetStateAction<Node|null>>;
}

export const AppContext = createContext<AppContext>(null);

function App() {
  console.log("NODE_ENV: " + JSON.stringify(process.env.NODE_ENV));
  const defaultUserProfile = {
    email: "Loading .. ",
    first_name: "Loading ..",
    last_name: "Loading ..",
    username: "Loading ..",
    is_staff: false
  };
  const [userProfile, setUserProfile] = useState<UserProfile>(defaultUserProfile);
  const [globalViewNode, setGlobalViewNode] = useState<Node|null>(null);
  const { token, setToken } = useToken();
  const [sideBarToggled, setSideBarToggled] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 800px)'
  });

  const callBackFunction = () => {
    setSideBarToggled(!sideBarToggled);
  }

  const callBackSetToggle = () => {
    setSideBarToggled(false);
  }

  useEffect(() => {
    async function getUserProfile() {
      UserRepository.Create().getUserProfile()
        .then(
          (response) => {
            setUserProfile(response.data);
          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getUserProfiles Error:" + JSON.stringify(_event))
            setUserProfile(defaultUserProfile)
          }
        )
        .catch(
          (error) => {
            console.error('There has been a problem with your getsites operation: ' + error.message);
          }
        );
    }

    getUserProfile();
  }, [token]);

  const context: AppContext = {
    setToken: setToken,
    parentCallback: callBackFunction,
    userProfile,
    isDesktopOrLaptop,
    globalViewNode,
    setGlobalViewNode
  };

  if (!token) {
    return <Login setToken={setToken} />
  }

  return (
    <AppContext.Provider value={context}>
      <div className="App" >
        <ToastContainer theme="colored" />
        <SamplSideBar initialCollapsed={false} setToken={setToken} toggled={sideBarToggled} onClick={callBackSetToggle} />
        <div className="bodyrow col">
          <section className='Main'>
            <Routes>
              <Route path="/" element={<Dashboard/>} />
              <Route path="dashboard" element={<Dashboard/>} />
              <Route path="billing" element={<Billing/>} />            
              <Route path="support" element={<Support/>} />
              <Route path="databases" element={<Databases/>} />
              <Route path="flows" element={<Flows/>} />
              <Route path="userprofile" element={<UserProfile/>} />
              <Route path="changepassword" element={<ChangePassword/>} />

              <Route path="accounts/*" element={<AccountRoutes/>} />
              <Route path="applications/*" element={<ApplicationRoutes/>} />
              <Route path="gateways/*" element={<GatewayRoutes/>} />
              <Route path="mqtt-accounts/*" element={<MqttAccountRoutes/>} />
              <Route path="qr/*" element={<QRRoutes/>} />
              <Route path="simcards/*" element={<SimcardsRoutes/>} />
              <Route path="sensors/*" element={<SensorRoutes/>} />
              <Route path="sensortypes/*" element={<SensorTypeRoutes/>} />
              <Route path="tenants/*" element={<TenantRoutes/>} />
              <Route path="users/*" element={<UserRoutes/>} />
            </Routes>
          </section>
          <Footer />
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
