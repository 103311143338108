import { AxiosRequestConfig } from 'axios';
import api from '../Service/api';
import ObjectFormDataMapper, { SkipProperty } from '../Libs/HTTP/ObjectFormDataMapper';

class GatewayRepository {
  readonly skippedProperties: SkipProperty[] = [
    ObjectFormDataMapper.SkipProperty("third_party_data"),
    ObjectFormDataMapper.SkipImagePropertyWhenNotAFile("img"),
    ObjectFormDataMapper.SkipProperty("owner"),
  ];

    static Create(): GatewayRepository {
        return new GatewayRepository();
    }

    getGateways(config: AxiosRequestConfig) {
        return api.get("gateways/", config);
    }

    getGatewaysList(config: AxiosRequestConfig) {
        return api.get("gateways/", config);
    }

    getGateway(eui, abortSignal?: AbortSignal) {
        return api.get("gateways/" + eui + "/", { signal: abortSignal });
    }
    
    async getByEui(eui, abortSignal?: AbortSignal) {
        let result = await api.get("gateways/" + eui, { validateStatus: (status) => status == 200 || status == 404, signal: abortSignal });
        if (result.status == 404) {
            return null;
        }

        if (result.status != 200) {
            throw new Error("Could not fetch gateway, http ("+ result.status +"): " + result.statusText);
        }

        return result.data;
    }

    createGateway(gateway, abortSignal?: AbortSignal) {
        return api.postForm("gateways/", gateway, { signal: abortSignal }, this.skippedProperties);
    }

    updateGateway(gateway, abortSignal?: AbortSignal) {
        return api.putForm(gateway.url, gateway, { signal: abortSignal }, this.skippedProperties);
    }

    deleteGateway(id, abortSignal?: AbortSignal) {
        return api.delete("gateways/" + id, { signal: abortSignal });
    }

    getGatewayTypes(searchParams) {
      return api.get("gatewaytypes/", searchParams);
    }
}

export default GatewayRepository;
