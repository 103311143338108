import React, { useContext, useState } from "react";
import { AppContext } from "../../App";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";
import NetworkRepository from "../../Repositories/NetworkRepository";
import AsyncLoadContainer from "../Common/AsyncLoadContainer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { Button } from "react-bootstrap";

const StaffLoriotLink = ({ sensor }) => {
    const { userProfile } = useContext(AppContext);
    const [link, setLink] = useState(null);

    const url = new URL(sensor.network);
    const networkId = url.pathname.split("/").filter(e => e != "").pop();

    if (! userProfile.is_staff || networkId == "12") {
      return <></>;
    }
    
    const asyncEffectState = useAsyncEffect({ fetchAsync:
        (abortSignal) => {
          async function getSensor(networkId) {
            {
              const response = await NetworkRepository.Create().getNetwork(networkId);
              const network = response.data;
              const apiurl = new URL(network.apiurl);
              const networkLoriotId = apiurl.pathname.split("/").filter(e => e != "").pop();
              setLink(`https://iotnet.teracom.dk/applications/${networkLoriotId}/devices/${sensor.eui}`);
            }
          }
          
          return getSensor(networkId);
        },
        deps: [networkId]
    });

    return <>
        <AsyncLoadContainer state={asyncEffectState}>
            <Button variant="outline-secondary" size="sm" href={link} target="_blank" style={{ float: 'right' }} title={"View in Loriot (staff only)"}>
                <FontAwesomeIcon icon={faUserSecret}
                /> Loriot <FontAwesomeIcon icon={faUpRightFromSquare} />
            </Button>
        </AsyncLoadContainer>
    </>;
}

export default StaffLoriotLink;
