import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from "react";
import GatewayRepository from '../../Repositories/GatewayRepository';
import { InputGroup } from 'react-bootstrap';

const GatewayTypesSelect = ({ changeHandler, defaultValue, disabled = false }) => {
  const [gatewayTypes, setGatewayTypes] = useState<{id, name, url}[]>([]);
  const urlParams = {
    params:
    {
      'offset': 0,
      'limit': 10000,
    }
  }
  useEffect(() => {

    async function getGatewayTypes() {
      console.log("getGatewayType async")
      GatewayRepository.Create().getGatewayTypes(urlParams)
        .then(
          (response) => {
            setGatewayTypes(response.data.results);
            console.log(gatewayTypes)

          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getGatewayTypes Error:" + JSON.stringify(_event))
            setGatewayTypes([{ "id": "N/A", "name": "N/A", url: null }]);
          }
        )
        .catch(
          (error) => {
            console.log('There has been a problem with your getsites operation: ' + error.message);
          }
        )
        ;
    }

    getGatewayTypes();
  }, [gatewayTypes.length]);

  return (
    <>
      <InputGroup className='input-group-flex mb-3'>
      <InputGroup.Text >Type</InputGroup.Text>

      <Form.Select
        required id="type"
        aria-label="Default select example"
        onChange={changeHandler}
        value={defaultValue}
        disabled={disabled}>
        <option value="">Select</option>
        {gatewayTypes.map((gatewayType) => {
          return (
            <option key={gatewayType.id} value={gatewayType.url}>
              {gatewayType.name}
            </option>
          );
        })}
      </Form.Select>
      </InputGroup>
    </>
  );
};

export default GatewayTypesSelect;
