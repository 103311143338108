import Form from 'react-bootstrap/Form';
import React, { useState, useMemo, useEffect, useCallback } from "react";
import SensorTypeRepository from '../../Repositories/SensorTypeRepository';
import AxiosRequestConfigBuilder from '../../Extensions/Axios/AxiosRequestConfigBuilder';
import useAsyncEffect from '../../Extensions/React/AsyncEffect/UseAsyncEffect';
import { InputGroup } from 'react-bootstrap';
import SenseReactSelect from '../Common/SenseReactSelect';
const SensorTypesSelect = ({ changeHandler, defaultValue, updateError }) => {
  const [sensorTypes, setSensorTypes] = useState<{ id, name, url }[]>([]);
  const configBuilder = AxiosRequestConfigBuilder.create()
    .withPaginationLimitAndOffset(1000);

  useAsyncEffect({
    fetchAsync: (abortSignal) => {
      async function getSensorTypes() {
        console.log("getSensorType async")
        SensorTypeRepository.Create().getSensorTypes(configBuilder.withAbortSignal(abortSignal).build())
          .then(
            (response) => {
              setSensorTypes(response.data.results);
            },
            (error) => {
              const _event =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              console.log("getSensorTypes Error:" + JSON.stringify(_event))
              setSensorTypes([{ id: "N/A", name: "N/A", url: null }])
            }
          )
          .catch(
            (error) => {
              console.log('There has been a problem with your getsites operation: ' + error.message);
            }
          )
          ;
      }

      return getSensorTypes();
    }, deps: []
  });


  // Generate options for the Select component
  const options = useMemo(() => sensorTypes.map(sensorType => ({
    label: sensorType.name, 
    value: sensorType.url,  
  })), [sensorTypes]); // Recompute when sensorTypes changes

  const isInvalid = () => (updateError["type"]==undefined)?(false):(true);
  return (
    <>
      {
        // Check if sensorTypes are loaded
        sensorTypes.length > 0 ? (
          <InputGroup className='input-group-flex mb-3' hasValidation>
            <InputGroup.Text>Type</InputGroup.Text>
            <SenseReactSelect 
              changeHandler={changeHandler} // Handle change events
              defaultValue={defaultValue}           // Set default value
              isInvalid={isInvalid()}     // Manage error state
              isDisabled={false}
              id={"type"}
              name={"sensortype"}
              options={options}   
                               // Pass available options
            />
            <Form.Control.Feedback type="invalid">
              {updateError["type"]}
            </Form.Control.Feedback>
          </InputGroup>
        ) : (
          <p>Loading...</p> // Or show a loading spinner
        )}
    </>
  );
};

export default SensorTypesSelect;
