import React, { useState } from "react";
import { DebounceInput } from 'react-debounce-input';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate, useSearchParams } from 'react-router-dom';
import {Form, Button, Row, Col, ToastContainer, InputGroup, Card} from 'react-bootstrap';
import GatewayTypesSelect from "./GatewayTypesSelect";
import NodeSelect from "../Common/NodeSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import LocationMap from "../Common/LocationMap";
import LorawanNetworkSelect from "./LorawanNetworkSelect";
import QRCodeRepository from "../../Repositories/QRCodeRepository";
import { toast } from 'react-toastify';
import GatewayRepository from "../../Repositories/GatewayRepository";
import SimcardsSelect from "./SimcardsSelect";

type Gateway = {
  eui, name, description, mac, type, lorawan_network, node, active, city, location, username, password, simcard, node__id
}

const defaultGateway: Gateway = {
  eui: null,
  name: null,
  description: null,
  mac: null,
  type: null,
  lorawan_network: null,
  node: null,
  active: false,
  city: null,
  location: null,
  username: null,
  password: null,
  simcard: null,
  node__id: null
}

const Gateway = () => {
  const [searchParams] = useSearchParams();
  const [gateway, setGateway] = useState<Gateway>({ ...defaultGateway, ["eui"]: searchParams?.get("eui") });
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState("");
  const [updateError, setUpdateError] = useState([]);
  const provider = new OpenStreetMapProvider();
  const [passwordType, setPasswordType] = useState("password");

  function doSearchAddress(value) {
    provider.search({ query: value }).then(function (result) {
      if (result.length > 0) {
        const { x, y, label } = result[0];
        setLocation({ lat: y, lng: x, zoom: 14 });
        setGateway(prevGateway => ({ ...prevGateway, location: y + "," + x }));
      }
    });
  }
  const togglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  }

  const handleInputChange = (event) => {
    console.log("Create.js (119):")
    const { id, value } = event.target;
    console.log(id, value)
    switch (id) {
      case 'mac':
        let cleanValue = String(value).replaceAll(":", "");
        cleanValue = cleanValue.toUpperCase();
        console.log(cleanValue)
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: cleanValue,
          ["eui"]: String(cleanValue).substring(0, 6) + 'FFFE' + String(cleanValue).substring(6, 12)
        }));
        break;
      case 'location':
        setLocation(getLocationArray(value));
        break;
      case 'city':
        doSearchAddress(value);
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: value,
        }));
        break;
      case 'active':
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: !(value === 'Active'),
        }));
        break;
      default:
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: value,
        }));
    }
  };

  const handleImageChange = (e) => {
    setGateway(prevGateway => ({ ...prevGateway, img: e.target.files[0] }));
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = event => {
    event.preventDefault();

    GatewayRepository.Create().createGateway(gateway)
      .then(async (response) => {
        console.log('Item created:', response.data);
        let qr = searchParams?.get("qr");
        console.log("qr: " + qr);
        if (qr != null) {
          await QRCodeRepository.Create().gatewayCreated(qr, gateway.eui);
        }

        toast("Gateway created!", { type: "success", autoClose: 3000 });
        setTimeout(() => navigate("/gateways/" + gateway.eui + "/edit"), 3000);
      })
      .catch(
        error => {
          console.error(error);
          toast("Error creating gateway.", { type: "error" });
          handleApiError(error, setUpdateError)
        }
      );
  };

  const getLocationArray = (locationstr) => {
    const [lat, lng] = String(locationstr).split(',');
    if (Number.isNaN(Number.parseFloat(lat)) || Number.isNaN(Number.parseFloat(lng))) {
      return null;
    } else {
      return { lat: +lat, lng: +lng, zoom: 14 };
    }
  };

  const navigate = useNavigate();

  return (
    <div>
      <Header>New gateway {gateway.eui || ''}</Header>

      <Divider width="100%" color="red" />
      <PageBody>
        <Form noValidate onSubmit={handleSubmit} >
          <Row md="auto">



            <Col className="mb-3 " >
              <Card className="gateway-card">
                <Card.Header>Essentials</Card.Header>
                <Card.Body>
                  <InputGroup className="mb-3 input-group-flex" >
                    <InputGroup.Text>Name</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder='Name of the gateway.. '
                      id='name'
                      value={gateway.name || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </InputGroup>


                  <InputGroup className="mb-3 input-group-flex" >
                    <InputGroup.Text>Description</InputGroup.Text>
                    <Form.Control as="textarea" aria-label="Description"

                      rows={3}
                      placeholder='Give your gateway a nice description.. '
                      id='description'
                      value={gateway.description || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </InputGroup>



                  <InputGroup className="mb-3 input-group-flex">
                    <InputGroup.Text >Address</InputGroup.Text>

                    <DebounceInput className="form-control"
                      label='address'
                      minLength={2}
                      debounceTimeout={500}
                      type="text"
                      placeholder='Address'
                      id='city'
                      value={gateway.city || ''}
                      onChange={handleInputChange}
                    />

                  </InputGroup>


                  <InputGroup className="mb-3 input-group-flex">
                    <InputGroup.Text >Coordinates</InputGroup.Text>

                    <DebounceInput
                      className="form-control form-control-sm"
                      label='location'
                      type="text"
                      placeholder='fx. 55.6928608,12.5992622'
                      id='location'
                      value={gateway.location}
                      onChange={handleInputChange}
                      required
                    />

                  </InputGroup>
                  <InputGroup className="input-group-flex">
                  <InputGroup.Text >Set status to</InputGroup.Text>
                  <Button 
                    className="form-control"
                    id="active"
                    variant={(gateway.active ? "success" : "danger")}
                    onClick={handleInputChange}
                    value={gateway.active ? "Active" : "Not Active"}
                  >{gateway.active ? "Active" : "Not Active"}</Button>
                  </InputGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mb-3">
              <Card className="gateway-card">
                <Card.Header>Advanced Settings</Card.Header>
                <Card.Body>
                  <InputGroup className="mb-3 input-group-flex" >
                    <InputGroup.Text>MAC</InputGroup.Text>
                    <Form.Control type="mac" placeholder="aa:bb:cc:aa:bb:cc"
                      onChange={handleInputChange}
                      id="mac" required value={gateway.mac} name="mac"
                      maxLength={17}
                      isInvalid={updateError["mac"]}

                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["mac"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-3 input-group-flex">
                    <InputGroup.Text>EUI</InputGroup.Text>
                    <Form.Control type="eui" placeholder="XXXXXXXXXXXXXXXX"
                      onChange={handleInputChange}
                      id="eui"
                      required
                      value={gateway.eui}
                      name="eui"
                      maxLength={16}
                      isInvalid={updateError["eui"]}

                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["eui"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex" hasValidation>
                    <InputGroup.Text>Username</InputGroup.Text>
                    <Form.Control type="username" placeholder="username"
                      onChange={handleInputChange}
                      id="username"
                      autoComplete="name"
                      required
                      value={gateway.username}
                      name="username"
                      maxLength={100}
                      isInvalid={updateError["username"]}

                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["username"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <InputGroup className="mb-3 input-group-flex" hasValidation>
                    <InputGroup.Text>Password</InputGroup.Text>
                    <Form.Control type={passwordType} placeholder="password"
                      onChange={handleInputChange}
                      id="password"
                      autoComplete="new-password"
                      required
                      value={gateway.password}
                      name="password"
                      maxLength={100}
                      isInvalid={updateError["password"]}

                    />
                    <Button onClick={togglePasswordType}>
                      {passwordType === "password" ? (
                        <svg
                          width="20"
                          height="17"
                          fill="currentColor"
                          className="bi bi-eye-slash-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                          <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="17"
                          fill="currentColor"
                          className="bi bi-eye-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                        </svg>
                      )}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      {updateError["password"]}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <GatewayTypesSelect changeHandler={handleInputChange} defaultValue={gateway.type}></GatewayTypesSelect>
                  <NodeSelect changeHandler={handleInputChange} defaultValue={null}></NodeSelect>
                  <LorawanNetworkSelect changeHandler={handleInputChange} defaultValue={gateway.lorawan_network}></LorawanNetworkSelect>
                  <SimcardsSelect changeHandler={handleInputChange} nodeId={gateway.node__id} defaultValue={gateway.simcard} eui={gateway.eui} updateError={updateError}/>
                </Card.Body>

              </Card>
            </Col>
            <Col classname="mb-3">
            <Card className="gateway-card">
              <Card.Header>Image</Card.Header>
              <Card.Body>
                  <img src={file} style={{ width: '400px' }} />
  
                <p>
                  <Form.Control
                    className="form-control"
                    type="file"
                    id="img"
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                  />
                </p>
              </Card.Body>
              </Card>
            </Col>
            <Col className="mb-3">
            <Card className="gateway-card">
              <Card.Header>Map</Card.Header>
              <Card.Body>  <LocationMap location={location} setLocation={setLocation} setDevice={setGateway} /></Card.Body>
            </Card>
            </Col>
          </Row>

          <div className="row">
            <div className="col mb-2" >
              <div className="d-flex justify-content-between">
                <div className="col start-0">
                  <Button variant="primary" type="submit" value="Create">Create</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row flex-grow-1 d-flex flex-col align-items-stretch">
            
            </div>
          </div>
        </Form>
      </PageBody>
    </div>
  );
}

export default Gateway;
