import Form from 'react-bootstrap/Form';
import React, { useMemo, useState } from "react";
import NodeRepository from '../../Repositories/NodeRepository';
import useAsyncEffect from '../../Extensions/React/AsyncEffect/UseAsyncEffect';
import AxiosRequestConfigBuilder from '../../Extensions/Axios/AxiosRequestConfigBuilder';
import SenseReactSelect from './SenseReactSelect';

const NodeSelectField = ({
  changeHandler,
  defaultValue,
  updateError={},
  disabled=false,
  elementId="node",
  valueKey="url",
  useFormSelect = false,
}) => {
  const [nodes, setNodes] = useState([{
    id: "",
    path: ""
  }]);
  const configBuilder = AxiosRequestConfigBuilder.create()
    .withPaginationLimitAndOffset(1000);

  useAsyncEffect({ fetchAsync: (abortSignal) => {
    async function getNodes() {
      NodeRepository.Create().getNodes(configBuilder.withAbortSignal(abortSignal).build())
        .then(
          (response) => {
            setNodes(response.data.results.sort((a, b) => a.path.localeCompare(b.path)));
          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getNodes Error:" + JSON.stringify(_event));
            setNodes([{ "id": "N/A", "path": "N/A" }]);
          }
        )
        .catch(
          (error) => {
            console.log('There has been a problem with your getsites operation: ' + error.message);
          }
        )
        ;
    }

    return getNodes();
  }, deps: [nodes.length]});

  const handleInputChange = (event) => {
    console.log(event);
    let node = null;
    if (event == null) {
      event = {
        target: {
          id: elementId,
          value: ""
        },
        value: ""
      }
    } else {
      // Update event structure with standardized target object
      event.target = {
        id: elementId,
        value: event.value
      }
      node = nodes.find((n) => n.id == event.target.value) ?? null;
    }
    
    changeHandler(event, node);
  };

  const handleInputChangeFormSelect = (event) => {
    handleInputChange(event?.target);
  }

  const options = useMemo(() => nodes.map(node => ({
    label: node.path, 
    value: node[valueKey],  
  })), [nodes]);

  const isInvalid = () => (updateError[elementId]==undefined)?(false):(true);

  if (useFormSelect) {
    return (
      <Form.Select 
          required
          id={elementId}
          aria-label="Default select example" 
          onChange={handleInputChangeFormSelect} 
          value={defaultValue} 
          isInvalid={isInvalid()}
          disabled={disabled}>
          <option value="">Select</option>
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
      </Form.Select>
    );
  }

  return (
    <>
      <SenseReactSelect
        changeHandler={handleInputChange}
        defaultValue={defaultValue}
        isInvalid={isInvalid()}
        id={elementId}
        name={"node"}
        options={options}
        isDisabled={disabled} />
    </>
  );
};

export default NodeSelectField;
